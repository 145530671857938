.root {
  color: red;
}
.grid {
  padding: "25px 0";
}

.title {
  flex-grow: 1;
  text-align: left;
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-weight: "1rem";
}
.menuLinks {
  text-decoration: none;
  color: white;
}
.menuBtn {
  background-color: "unset";
  padding: "6px";
  font-size: "0.775rem";
  box-shadow: "none";
}

.donate {
  border-width: 0;
  padding: "5px 10px";
  cursor: "pointer";
  width: 50px;
  height: 40px;
  border-radius: "5px";
}
.donate:hover {
  background-color: #031631;
  color: white;
  animation: 0;
}

.iconGrid {
  justify-content: "space-evenly";
}
.gridbtn:hover {
  background-color: red;
}
.hamMenu {
  color: red;
  background-color: "#22477E";
  background: "linear-gradient(132deg, #22477E 30%, #3567B2 90%)";
  /* width: "200px"; */
}
.hamMenuItem {
  height: "70px";
  background-color: "red";
}
/* .hamMenuItem:hover {
  background-color: "#1A355D";
} */
.hamMenuIcon {
  padding-right: "10px";
}
.show {
  display: inline-block;
}
.hide {
  display: none;
}
#hello {
  display: "none";
}
#title {
  /* flex-grow: 1; */
  text-align: left;
  /* font-size: 10rem; */
}
#title1 {
  text-align: left;
}
#button{
  background-color: "unset";
  word-wrap: break-word;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  /* text-align: center; */
}
#button:hover {
  background-image: linear-gradient(#0a1d56, #6895d2);
  box-shadow: 0 0 10px skyblue;
}
#menuItems:hover {
  background-image: linear-gradient(#0a1d56, #6895d2);
  color: white;
}
#menuItems_icon {
  color: black;
}
.donate:hover {
  background-image: linear-gradient(#0a1d56, #6895d2);
  box-shadow: 0 0 10px skyblue;
}
.hovergrid{
  display: none;
}
.grid:hover{
  .hovergrid{
    display: block;
  }
}

.routerlink{
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 100%;
  ;
}
@media screen and (min-width: 1201px) {
  #toggelebtn {
    display: none;
  }
  #paypalbutton {
    display: none;
  }
  #menuitem {
    display: none;
  }
  #class {
    display: block;
  }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
  #toggelebtn {
    display: block;
  }
  #paypalbutton {
    display: block;
  }
  #class {
    display: none;
  }
}
@media screen and (max-width:768px) and (min-width:577px){
  #class {
    display: none;
  }
  #toggelebtn {
    display: flex;
    /* flex-direction: column; */
  }
  #title {
    text-align: center;
  }
  #title1 {
    text-align: center;
  }
}


@media screen and (max-width: 576px) and (min-width: 0px) {
  #class {
    display: none;
  }
  #toggelebtn {
    display: flex;
    flex-direction: column;
  }
  #title {
    text-align: center;
  }
  #title1 {
    text-align: center;
  }
}
