#cards{
    width: 80%;
}
#tabs{
    padding: 20px;
}
#card0{
    height: 210px;
}

@media screen and (max-width:576px) {
    #cards{
        width: 90%;
    }
    #tabs1{
        padding: 0px;
    }
}
