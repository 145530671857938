/* .root{
    background-color: burlywood;
} */
#stepsgrid{
    width: 80%;
}
@media only screen and (max-width: 768px) {
    /* #infomeet {
        background-color: red;
    } */
    #stepsgrid{
        width: 100%;
    }

    /* #infomeetcard{
        height: auto;
    }
    #preparationcard{
        height: auto;
    }
    #trainingcard{
        height: auto;
    }
    #familycard{
        height: auto;
    } */
}

@media only screen and (min-width: 768px) {
    #stepsgrid{
        width: 100%;
    }

    /* #infomeetcard{
        height: 15rem;
    }
    #preparationcard{
        height: 15rem;
    }
    #trainingcard{
        height: 13rem;
    }
    #familycard{
        height: 13rem;
    } */
}