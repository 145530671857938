/* Slideshow */

/* .slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
} */
#imgStyles{
  background-size: "contain";
  background-repeat: "no-repeat";
  box-shadow: "0 3px 5px 2px rgb(90 78 80 / 42%)";
  height: 350px;
  width: 85%;
}

/* .slideshowSlider {
  white-space: nowrap;
  transition: ease 2500ms;
}
.slide {
  display: inline-block;
  width: 100%;
  box-shadow: inset;
} */
/* #box{
  top: 0;
  margin-top: 155px;
  margin-left: 120px;
  position: absolute;
} */

/* Buttons */

/* .slideshowDots {
  text-align: center;
  position: relative;
}

.slideshowDot {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}
#images {
  width: 100%;
} */
@media screen and (min-width: 1200px) {
  /* #images {
    width: 85%;
  } */
}
@media screen and (max-width: 992px) and (min-width: 769px) {
  /* #images {
    width: 90%;
    height: 300px;
  } */
  #imgStyles{
    background-size: "contain";
    background-repeat: "no-repeat";
    box-shadow: "0 3px 5px 2px rgb(90 78 80 / 42%)";
    /* background-color: red; */
    height: 220px;
    width: 90%;
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  /* #images {
    height: 280px;
    width: 85%;
  } */
  #imgStyles{
    background-size: "contain";
    background-repeat: "no-repeat";
    box-shadow: "0 3px 5px 2px rgb(90 78 80 / 42%)";
    /* background-color: red; */
    height: 200px;
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  /* .slideshowSlider {
    width: 100%;
  }
  #images {
    height: 250px;
    width: 100%;
  } */
  #imgStyles{
    background-size: "contain";
    background-repeat: "no-repeat";
    box-shadow: "0 3px 5px 2px rgb(90 78 80 / 42%)";
    /* background-color: red; */
    height: 150px;
    width: 100%;
  }
}
