#maingrid{
    width: 80%;
}
#table{
    width: 80%;
}
@media screen and (max-width:768px) {
    #maingrid{
        width: 100%;
    }
    #table{
        width: 100%;
    }
}