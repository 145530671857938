#maingrid{
    width: 80%;
}

@media screen and (min-width:0px) and (max-width:768px) {
    #youtubevideo{
        width: 250px; ;
        height: 250px;
    }
    #maingrid{
        width: 90%;
    }
 
}
@media screen and (min-width:769px) and (max-width:1200px) {
    #youtubevideo{
        width: 70%;
        /* height: 100%; */
        height: 300px;
    }
}
@media screen and (min-width:1201px) {
    #youtubevideo{
        width: 100%;
        height: 100%;
    }
}