.cardsgrid {
  padding: 35px;
}
.cardsItems {
  padding: "20px";
}
.card {
  padding: "20px";
  background-color: "#3567b20f";
  /* '& .MuiButtonBase-root': {
      cursor: 'default'; 
     background-color: '#3567b20f'; 
    
    } */
}
.gridhover {
  display: none;
}
.table{
  border: 1px solid lightgrey;
  box-shadow: 0px 0px 10px 1px grey;
  font-weight: bold;
  width: 80%;
}
.tablecell{
  cursor: pointer;
}
.tablecell:hover{
  background-color: #22477e;
  color: white;
}
#ourservices {
  background-color: #e0f4ff;
  width: 80%;
  cursor: pointer;
  color: black;
  box-shadow: 0px 0px 5px 1px #22477e;
  border-radius: 5px;
}


 #ourservices1 {
  background-color: #e0f4ff;
  width: 80%;
  cursor: pointer;
  color: black;
  box-shadow: 0px 0px 5px 1px #22477e;
  border-radius: 5px;
  /* animation: blink-animation 1s infinite alternate; */
}

#ourservices1:hover {
  /* animation: none;  */
  background-color: #3567b2;
  color: white;
  box-shadow: 0px 0px 5px 3px #22477e;
}

@keyframes blink-animation {
  0% {
    background-color: #e0f4ff;
    color: black;
    box-shadow: 0px 0px 5px 1px #22477e;
  }
  100% {
    background-color: #e0f4ff;
    /* color: white; */
    box-shadow: 0px 0px 5px 3px #22477e;
  }
} 

.youthgrid{
  margin-bottom: 20px;
  gap: 20px;
  display: flex;
}
#ourservices:hover {
  background-color: #3567b2;
  color: white;
  box-shadow: 0px 0px 5px 3px #e0f4ff;
  .box1 {
    display: block;
  }
}
.ourservices {
  background-color: #e0f4ff;
  width: 80%;
  cursor: pointer;
  color: black;
  box-shadow: 0px 0px 5px 1px #22477e;
  border-radius: 5px;
}
.ourservices:hover {
  background-color: #3567b2;
  color: white;
  box-shadow: 0px 0px 5px 3px #e0f4ff;
  .box1 {
    display: block;
  }
}
.icons2 {
  text-decoration: none;
  color: black;
  width: 100%;
}
.icons2:hover {
  background-color: white;
}
#iconsgrid {
  width: 150px;
  height: 150px;
  font-weight: 200px;
  /* background-color: white; */
  box-shadow: 0px 0px 5px 1px black;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;

}
.youtubegrid {
  box-shadow: "2px 2px 11px 1px rgb(90 78 80 / 55%)";
  padding: "30px";
  display: "flex";
  justify-content: "center";
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  width: "80%";
}
.cardTitle {
  color: "#d04e53";
}
.media {
  padding: "15px";
  color: "#22477E";
}
.listIcon {
  color: "#22477E";
  height: 10px;
}

.secListRedirects {
  text-decoration: "none";
  color: "#22477E";
}
.readMore {
  justify-content: "center";
  padding: "10px 0";
}
#resources_title {
  font-size: 1.5rem;
  color: #255aad;
  font-weight: bold;
  text-align: "center";
}
#resourcesHead{
  font-size: 1.0rem;
  padding: 10px;
}
.fixed-ads {
  position: fixed; /* Fixed position */
  right: 0; /* Align to the right */
  top: 0; /* Align to the top */
  width: 200px; /* Set a width for the ads container */
  height: 100%; /* Full height */
  overflow-y: auto; /* Allow scrolling within the ad container if content overflows */
  z-index: 100; /* Ensure it stays on top of other content */
}

.icons {
  text-decoration: none;
}

.icons:hover {
  background-color: #e0f4ff;
}
.icons-text {
  text-decoration: none;
  width: 100%;
}
.icons-text:hover {
  background-color: white;
}
.contentgrid {
  display: flex;
  justify-content: center;
  /* width: 0%; */
  margin-bottom: 30px;
  margin-top: 20px;
}
.countgrid {
  width: 60%;
  margin-top: 20px;
}
#table{
  /* margin-top: 20px; */
  width:40%;
}
@media screen and (min-width: 1201px) {
  #hello {
    height: 170px;
    
  }
  .card {
    padding: 20px;
  }
  .cardsItems {
    padding: 10px;
  }
  #table{
    margin-top: 20px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 993px) {
  #hello {
    height: 150px;
  }
  .card {
    padding: 20px;
  }
  .cardsItems {
    padding: 10px;
  }
  #table{
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) and (min-width: 769px) {
  #hello {
    height: 120px;
  }
  .card {
    padding: 20px;
  }
  .cardsItems {
    padding: 10px;
  }
  #resources_title {
    font-size: 1.3rem;
    color: #255aad;
    font-weight: bold;
    text-align: "center";
  }
  #table{
    margin-top: 0px;
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  #hello {
    height: 100px;
  }
  .card {
    padding: 20px;
  }
  .cardsItems {
    padding-bottom: 10px;
  }
  #resources_title {
    font-size: 1.3rem;
    color: #255aad;
    font-weight: bold;
    text-align: "center";
  }
  .contentgrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #iconsgrid {
    width: 120px;
    height: 120px;
    font-weight: 150px;
    font-size: 1.2rem;
    /* background-color: white; */
    box-shadow: 0px 0px 5px 1px black;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #resourcesHead{
    font-size: 0.8rem;
  }
  .youthgrid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  #table{
    margin-top: 0px;
  }
}
@media screen and (max-width: 576px) and (min-width: 0px) {
  #hello {
    height: 100px;
  }
  .card {
    padding: 10px;
  }
  .cardsItems {
    padding-bottom: 10px;
  }
  .cardsgrid {
    padding: 10px;
  }
  .media {
    padding: "15px";
    color: "#22477E";
  }
  .resourcesgrid {
    display: flex;
    flex-wrap: wrap;
  }
  /* .ticker{
    display: none;
  } */
  .contentgrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .countgrid {
    width: 90%;
  }
  #resourcesHead{
    font-size: 0.8rem;
  }
  .youthgrid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  #table{
    padding: 20px;
  }
}
