#accordionsgrid{
    width: 80%;
    background-color: white;
    padding: 10px;
}
@media screen and (max-width:768px) {
    #accordionsgrid{
        width: 95%;
    }
}
@media screen and (min-width:1440px) {
    #question{
        font-size: 1.2rem;
    }
}