#responsivegrid{
    display: none;
}
#maingrid{
    width: 80%;
} 

@media screen and (max-width: 886px) {
    /* Styles for small screens */
    /* #miltarygrid{
        display: none;
    }  */
    #responsivegrid{
        display: block;
    }
    #tablegrid{
        width: 80%;
        padding: 10px;
        /* background-color: yellow; */
    }
  }
  @media screen and (min-width:0px) and (max-width: 576px) {
#maingrid{
    width: 100%;
}  
}
