/* RootLayout.css */


  .ads-container {
    position: fixed;
    /* background-color:lightgrey; */
    border: 1px solid black;
    right: 0;
    top: 0;
    width: 180px;
    /* height: 77.5%; */
    overflow-y: auto;
    /* z-index: 100; */
    margin-top: 64px; /* Adjust the top margin to account for the header height */
  }

  
  .scroll-up-button {
    background-color: rgb(34 71 126 / 72%);
    bottom: 100px;
    height: 40px;
    width: 40px;
  }
  .main_div{
    width: 90%;
  }
  @media screen and (min-width:768px) {
    .ads-container{
        width: 10%;

    }
 }
 @media (max-width: 768px) {
    .ads-container {
      display: none;
      width: 0%;
       /* Hide ads on small screens */
    }
  .main_div{
    width: 100%;
  }
}