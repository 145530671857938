
 .mainGrid {
  width: 80%;
  height: 100%;
} 

@media screen and (max-width: 425px) {
  .mainGrid {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .openAccordion {
    display: none;
  }
  .hideAccordion {
    display: block;
  }
}
@media screen and (min-width: 769px) {
  .openAccordion {
    display: block;
  }
  .hideAccordion {
    display: none;
  }
}
