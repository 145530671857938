.agencydetails {
  /* margin-bottom: 2rem; */
  display: flex;
  padding: 10px;
  border: 0.5px solid black;
  border-radius: 10px;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.zipContainer {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 768px) {
  .agencydetails {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media screen and (max-width:576px) {
    .mainGrid{
        width: 90%;
    }
}
