
@media (min-width:0px) and (max-width:768px) {
    #sub-grid{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }
    #header{
        text-align: center;
    }
   
}

@media (min-width:768px) and (max-width:1024px) {
    #sub-grid{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }
    #header{
        text-align: center;
    }
   
}