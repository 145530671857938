.dollar-btn-div:hover {
  background: #bfeaf5;
  cursor: pointer;
  box-shadow: 0 0 5px #85CDFD, 0 0 30px #85CDFD;
}
#donate-btn:hover {
  background-image: linear-gradient(#22477e, #22477e);
  color: white;
  box-shadow: 0 0 10px #22477e;
}
#button_text:hover {
  color: white;
}


@media screen and (min-width: 1201px) {
  #border-div {
    /* background-color: forestgreen; */
    height: 70px;
    width: 50%;
    margin-right: -80px;
  }
  #donate-btn {
    /* padding: 10px; */
    height: 50px;
    width: 300px;
  }
  .donatetxt {
    font-size: 1rem;
  }
  .dollar-btn-div {
    height: 100px;
    width: 100px;
  }
  .dollar-glf {
    height: 60px;
  }
  .right-div {
    /* background-color: orange; */
    height: 70px;
    width: 50%;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    margin-left: -45px;
  }
  #contribution {
    font-size: 1.5rem;
    padding-left: "30px";
  }
}
@media screen and (max-width: 1200px) and (min-width: 993px) {
  #border-div {
    /* background-color: forestgreen; */
    height: 70px;
    width: 450px;
    margin-right: -50px;
  }
  #donate-btn {
    /* padding: 10px; */
    height: 50px;
    width: 200px;
  }
  .donatetxt {
    font-size: 1rem;
  }
  .dollar-btn-div {
    height: 100px;
    width: 100px;
  }
  .dollar-glf {
    height: 55px;
  }
  .right-div {
    /* background-color: orange; */
    height: 70px;
    width: 550px;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    margin-left: -40px;
  }
  #contribution {
    font-size: 1.2rem;
    padding-left: "30px";
  }
}
@media screen and (max-width: 992px) and (min-width: 769px) {
  #border-div {
    /* background-color: forestgreen; */
    height: 70px;
    width: 350px;
    margin-right: -30px;
  }
  #donate-btn {
    /* padding: 10px; */
    height: 50px;
    width: 200px;
  }
  .donatetxt {
    font-size: 1rem;
  }
  .dollar-btn-div {
    height: 100px;
    width: 100px;
  }
  .dollar-glf {
    height: 50px;
  }
  .right-div {
    /* background-color: orange; */
    height: 70px;
    width: 450px;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    margin-left: -40px;
  }
  #contribution {
    font-size: 1.1rem;
    padding-left: "30px";
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  #border-div {
    /* background-color: forestgreen; */
    height: 70px;
    width: 250px;
    margin-right: -25px;
  }
  #donate-btn {
    padding: 10px;
    width: 150px;
    /* margin-bottom: 10px; */
  }
  .donatetxt {
    font-size: 1rem;
  }
  .dollar-btn-div {
    height: 90px;
    width: 90px;
  }
  .dollar-glf {
    height: 50px;
  }
  .right-div {
    /* background-color: orange; */
    height: 70px;
    width: 350px;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    margin-left: -40px;
  }
  #contribution {
    font-size: 0.9rem;
    padding-left: "30px";
  }
}
@media screen and (max-width: 576px) and (min-width: 0px) {
  #border-div {
    /* background-color: forestgreen; */
    height: 70px;
    width: 170px;
    margin-right: -20px;
  }
  #donate-btn {
    margin-right:20px;
    width: 90px;
    /* margin-bottom:"10px"; */
  }
  .donatetxt {
    font-size: 0.9rem;
  }
  .dollar-btn-div {
    height: 80px;
    width: 80px;
  }
  .dollar-glf {
    height: 50px;
  }
  .right-div {
    /* background-color: orange; */
    height: 70px;
    width: 300px;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    margin-left: -45px;
  }
  #contribution {
    font-size: 0.8rem;
    padding-left: "30px";
  }
  #button_text{
    font-size: 0.8rem;
  }
}
