#Lists ul li{
    padding-bottom: 10px;
    /* text-decoration: none; */
    list-style-type:decimal;
}
#listslink{
    /* text-decoration: none; */
    color:blue ;
}

@media screen and (min-width:0px) and (max-width:768px){
    #regions{
        display: none;
    }
    #links{
        display: none;
    }
}
@media screen and (min-width:0px) and (max-width:576px){
    #maingrid{
        width: 100%;
    }
    #title{
        font-size: 1.2rem;
    }
}
@media screen and (min-width:576px) and (max-width:768px){
    #maingrid{
        width: 80%;
    }
    #title{
        font-size: 1.3rem;
    }
}
@media screen and (min-width:769px){
    #partners_acoording{
        display: none;
    }
    #maingrid{
        width: 83%;
    }
    #title{
        font-size: 1.5rem;
    }

}