#identifytable{
    width: 85%;
}
#tablecontainer1{
    width: 70%;
}
#tablecontainer2{
    width: 70%;
}
@media screen and (max-width:768px) {
    #identifytable{
        width: 100%;
    } 
    #tablecontainer1{
        width: 100%;
    }   
    #tablecontainer2{
        width: 100%;
    }
}