.footerdiv {
  background-color: "linear-gradient(132deg, #22477E 30%, #3567B2 90%)";
  color: "white";
}
.Typography {
  color: red;
}
/* .left-div {
  font-size: 2rem;
} */
#twitter:hover {
  background-image: linear-gradient(#0a1d56, #6895d2);
  box-shadow: 0 0 10px skyblue;
}
#contentgrid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}
#iconsgrids {
  text-align: "right";
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #22477e;
  padding-right: 20px;
  z-index: 0;
}
@media screen and (max-width: 768px) {
  #contentgrid {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #22477e;
    padding-left: 0px;
  }

  #iconsgrids {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    align-items: center;
    background-color: #22477e;
    padding-right: 0px;
  }
}
