.tooltip1 {
  position: absolute;
  top: 2px;
  right: 20%;
  /* line-height: 0.8rem; */
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  display: none;
  /* max-width: 100px; */
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip1.visible {
  display: block;
}
.tooltip2 {
  position: absolute;
  top: 2px;
  right: 20%;
  /* line-height: 0.8rem; */
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  display: none;
  /* max-width: 100px; */
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip2.visible {
  display: block;
}
.tooltip3a {
  position: absolute;
  top: 2px;
  right: 20%;
  /* line-height: 0.8rem; */
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  display: none;
  /* max-width: 100px; */
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip3a.visible {
  display: block;
}
.tooltip3b {
  position: absolute;
  top: 2px;
  right: 20%;
  /* line-height: 0.8rem; */
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  display: none;
  /* max-width: 100px; */
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip3b.visible {
  display: block;
}
.tooltip3 {
  position: absolute;
  top: 2px;
  right: 20%;
  /* line-height: 0.8rem; */
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  display: none;
  /* max-width: 100px; */
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip3.visible {
  display: block;
}
.tooltip4 {
  position: absolute;
  top: 2px;
  right: 20%;
  /* line-height: 0.8rem; */
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  display: none;
  /* max-width: 100px; */
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip4.visible {
  display: block;
}
.tooltip5 {
  position: absolute;
  top: 2px;
  right: 20%;
  /* line-height: 0.8rem; */
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  z-index: 1000;
  display: none;
  /* max-width: 100px; */
  font-size: 0.8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip5.visible {
  display: block;
}
.tooltip6 {
  position: absolute;
  /* top: 10px; */
  right: 10%;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  z-index: 1000;
  display: none;
  font-size: 0.8rem;
  max-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip6.visible {
  display: block;
}
.tooltip7 {
  position: absolute;
  /* top: 10px; */
  right: 10%;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  z-index: 1000;
  display: none;
  font-size: 0.8rem;
  max-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip7.visible {
  display: block;
}
.tooltip8 {
  position: absolute;
  /* top: 10px; */
  right: 10%;
  font-size: 0.8rem;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  z-index: 1000;
  display: none;
  max-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip8.visible {
  display: block;
}
.tooltip9 {
  position: absolute;
  top: 10px;
  right: 10%;
  /* padding: 8px; */
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  z-index: 1000;
  font-size: 0.8rem;
  display: none;
  max-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip9.visible {
  display: block;
}
.tooltip10 {
  position: absolute;
  top: 10px;
  right: 10%;
  /* padding: 8px; */
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  z-index: 1000;
  font-size: 0.8rem;
  display: none;
  max-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip10.visible {
  display: block;
}
.tooltip11 {
  position: absolute;
  /* top: 10px; */
  right: 10%;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  font-size: 0.8rem;
  z-index: 1000;
  display: none;
  max-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

.tooltip11.visible {
  display: block;
}
/* 
@media screen and (min-width: 1201px) {
  .tooltip1 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip2 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip3 {
    top: 10px;
    right: 10%;
   
    left: 10%;
  }

  .tooltip3a {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }
  .tooltip3b {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }
  .tooltip4 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip5 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip6 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip7 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip8 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip9 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip10 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip11 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 993px) {
  .tooltip1 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip2 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip3 {
    top: 10px;
    right: 10%;
   
    left: 10%;
  }

  .tooltip3a {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }
  .tooltip3b {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }
  .tooltip4 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip5 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip6 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip7 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip8 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip9 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip10 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }

  .tooltip11 {
    top: 10px;
    right: 10%;
   
    left: 10%;
    max-width: 200px;
  }
}*/
@media screen and (min-width: 1024px) {
  .tooltip1 {
    /* top: 10px; */
    right: 20%;
    /* left: 10%; */
    max-width: 200px;
  }

  .tooltip2 {
    /* top: 10px; */
    right: 20%;
    /* left: 10%; */
    max-width: 200px;
  }

  .tooltip3 {
    /* top: 10px; */
    right: 20%;
    /* left: 10%; */
    max-width: 200px;
  }

  .tooltip3a {
    /* top: 10px; */
    right: 20%;
    /* left: 10%; */
    max-width: 200px;
  }
  .tooltip3b {
    /* top: 10px; */
    right: 20%;
    /* left: 10%; */
    max-width: 200px;
  }
  .tooltip4 {
    /* top: 10px; */
    right: 20%;
    /* left: 10%; */
    max-width: 200px;
  }

  .tooltip5 {
    /* top: 10px; */
    right: 20%;
    /* left: 10%; */
    max-width: 200px;
  }

  .tooltip6 {
    /* top: 10px; */
    bottom: 5%;
    right: 5%;
    max-width: 160px;
  }

  .tooltip7 {
    /* top: 10px; */
    bottom: 5%;
    right: 5%;
    max-width: 160px;
  }

  .tooltip8 {
   /* top: 10px; */
   bottom: 5%;
   right: 5%;
   max-width: 160px;
  }

  .tooltip9 {
    top: 20%;
    left: 10%;
    max-width: 160px;
  }

  .tooltip10 {
    top: 20%;
    left: 10%;
    max-width: 160px;
  }

  .tooltip11 {
    /* top: 10px; */
    bottom: 5%;
    right: 5%;
    max-width: 160px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .tooltip1 {
    /* top: 10px; */
    top: 0px;
    right: 10%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }

  .tooltip2 {
    top: 0px;
    right: 10%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }

  .tooltip3 {
    top: 0px;
    right: 10%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }

  .tooltip3a {
    top: 0px;
    right: 10%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }
  .tooltip3b {
    top: 0px;
    right: 10%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }
  .tooltip4 {
    top: 0px;
    right: 10%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }

  .tooltip5 {
    top: 0px;
    right: 10%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }

  .tooltip6 {
    bottom: 0%;
    right: 5%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }

  .tooltip7 {
    bottom: 0%;
    right: 5%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }

  .tooltip8 {
    bottom: 0%;
    right: 5%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }

  .tooltip9 {
    top: 10%;
    left: 10%;
    max-width: 120px;
  }

  .tooltip10 {
    top: 10%;
    left: 10%;
    max-width: 120px;
  }

  .tooltip11 {
    bottom: 0%;
    right: 5%;
    height: 85px;
    /* left: 10%; */
    max-width: 120px;
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  .tooltip1.visible {
    display: none;
  }
  .tooltip2.visible {
    display: none;
  }
  .tooltip3.visible {
    display: none;
  }
  .tooltip3a.visible {
    display: none;
  }
  .tooltip3b.visible {
    display: none;
  }
  .tooltip4.visible {
    display: none;
  }
  .tooltip5.visible {
    display: none;
  }
  .tooltip6.visible {
    display: none;
  }
  .tooltip7.visible {
    display: none;
  }
  .tooltip8.visible {
    display: none;
  }
  .tooltip9.visible {
    display: none;
  }
  .tooltip10.visible {
    display: none;
  }
  .tooltip11.visible {
    display: none;
  }
}
@media screen and (max-width: 576px) and (min-width: 0px) {
  .tooltip1.visible {
    display: none;
  }
  .tooltip2.visible {
    display: none;
  }
  .tooltip3.visible {
    display: none;
  }
  .tooltip3a.visible {
    display: none;
  }
  .tooltip3b.visible {
    display: none;
  }
  .tooltip4.visible {
    display: none;
  }
  .tooltip5.visible {
    display: none;
  }
  .tooltip6.visible {
    display: none;
  }
  .tooltip7.visible {
    display: none;
  }
  .tooltip8.visible {
    display: none;
  }
  .tooltip9.visible {
    display: none;
  }
  .tooltip10.visible {
    display: none;
  }
  .tooltip11.visible {
    display: none;
  }
}
