#cerebral_palsy_table{
    width: 60%;
    /* background-color: red; */
}

@media screen and (max-width: 786px) {
    #cerebral_palsy_table{
        /* background-color: blue; */
        width:  90%;
    }  
    
}