
@media screen and (min-width:0px) and (max-width:768px) {
.grid1{
    display: none;
} 
#grid2{
  display: block;
}
#tabs{
  background-color: white;
  display:none;
}
#tabs2{
  background-color: violet;
  display: none;
}
#steps{
  /* display:none; */
}
}

@media screen and (min-width:769px){
  #grid2{
    display: none;
  }
  
}