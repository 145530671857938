#maingrid{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
    width: 80%;
}
@media screen and (max-width:576px) {
    #maingrid{
        width: 90%;
    }
}