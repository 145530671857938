#table1 {
  width: 70%;
}
#table2 {
  width: 70%;
}
#table3 {
  width: 70%;
}
#maingrid{
    width: 70%;
}

@media screen and (max-width: 786px) {
  #table1 {
    width: 100%;
  }
  #table2 {
    width: 100%;
  }
  #table3 {
    width: 100%;
  }
  #maingrid{
    width: 100%;
}

}
