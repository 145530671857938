

@media screen and (min-width:0px) and (max-width:768px) {
    #youtubevideo1{
        width:300px ;
        height: 300px;
    }
}
@media screen and (min-width:769px) and (max-width:1200px) {
    #youtubevideo1{
        width: 400px;
        height: 300px;
    }
}
@media screen and (min-width:1201px) {
    #youtubevideo1{
        width: 500px;
        height: 300px;
    }
}