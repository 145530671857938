#table2{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#table1{
    width: 80%;
}
#table3{
    width: 80%;
}
#maingrid{
    width: 80%;
}
@media (min-width:0px) and (max-width:768px) {
    #sub-grid{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }
    #table2{
        width: 100%;
    }
    #table1{
        width: 100%;
    }
    #table3{
        width: 100%;
    }
    #tablecontainer1{
        width: 100%;
    }
    #tablecontainer2{
        width: 100%;
    }
    #maingrid{
        width: 100%;
    }
}
@media (min-width:768px)  {
    #sub-grid{
        gap: 70px;
    }
    #tablecontainer1{
        width: 80%;
    }
    #tablecontainer2{
        width: 80%;
    }
}
@media (min-width:1241px) and (max-width:1440px) {
    #sub-grid{
        gap: 100px;

    }
}
@media (min-width:1441px) and (max-width:2000px) {
    #sub-grid{
        gap: 280px;

    }
}