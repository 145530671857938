#table1{
    width: 80%;

}
#table2{
    width: 80%;
}
@media screen and (max-width:786px) {
    #table1{
        width: 100%;
    }
    #table2{
        width: 100%;
    }
}