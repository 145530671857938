.root{
    flex-grow: 1;
    padding: '30px';
}
.box1{
    padding: "25px";
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

.accordionBody {
    background-color: '#738db33d';
    padding: '40px'
}
.caret {
    min-width: '20px';
    color: '#1A355D';
}
.maingrid{
    width: 80%;
    /* background-color: green; */
}

@media screen and (max-width:576px) and  (min-width:0px)  {
    
.maingrid{
    width: 100%;
}
}
 